import { Fragment, useContext, useEffect } from 'react';
import { Col, Empty } from 'antd';

import {
  array, bool, object, string, number, any,
} from 'prop-types';

import Product from 'components/product';
import { DEFAULT_LIMIT, getMarket } from 'utils/helpers';
import { IS_NNORMAL } from 'utils/constants/system';
import { TIER_POSITIONS } from 'utils/constants/cms';
import { i18nContext } from 'context/i18n';
import styles from './style.module.css';

function WithMiddle({ isMiddle, children, loadPage }) {
  if (isMiddle && loadPage) {
    return (
    <>
      {loadPage}
      {children}
    </>
    );
  }
  return children;
}

function ProductsGrid({
  origin, products,
  colXS, colMD, colLG, colXL, colXXL,
  withHover, withSKU,
  withLabel, currentList, camperlab, currentListName, recalculating, voucherApplied,
  withModal, defaultLimit, page, squareImg, loadPage, saveLastProduct = false,
}) {
  const { t, locale } = useContext(i18nContext);
  const market = getMarket(locale);

  useEffect(() => {
    if (window) {
      sessionStorage.setItem('gridUrl', window.location.href);
    }
  }, []);

  const middleIndex = Math.floor(products.length / 2);

  return (
    <>
    {
      products && products.length > 0 &&
        products.map(({
          id, name, targetId,
          prices, images, sizes, isTier,
          isPattersonTier, renderTier,
          tierImg, tierSize, tierType,
          family, colors, url, sustainabilities,
          conceptName, membersOnly, noLink,
        }, index) => {
          const isMiddle = index === middleIndex;

          if (isTier) {
            if (isPattersonTier) {
              return (
                <WithMiddle isMiddle={isMiddle} key={`${index}-tier`} loadPage={loadPage}>
                  <Col
                    xs={tierSize ? tierSize.xs : colXS}
                    md={tierSize ? tierSize.md : colMD}
                    lg={tierSize ? tierSize.lg : colLG}
                    xl={tierSize ? tierSize.xl : colXL}
                    xxl={tierSize ? tierSize.xxl : colXXL}
                  >
                  <div className={`${styles.gridPhoto} ${styles.gridTierPhoto}`}>
                    {/* FIXME: This may be deleted completely? */}
                    {!IS_NNORMAL && tierImg ?
                      (<img src={tierImg} className={styles.tierImg} />) :
                      null}
                    <div className={tierType !== TIER_POSITIONS.STANDALONE_LEFT ? styles.tierWrapper : `${styles.tierWrapperStandalone}`}>
                      {renderTier}
                    </div>
                  </div>
                </Col>
                </WithMiddle>
              );
            }
            return (
              <WithMiddle isMiddle={isMiddle} key={`${index}-tier`} loadPage={loadPage}>
                renderTier
              </WithMiddle>
            );
          }
          return (
            <WithMiddle isMiddle={isMiddle} key={`product-${id}`} loadPage={loadPage}>
              <Product
                currentList={currentList}
                currentListName={currentListName}
                index={((page - 1) * defaultLimit) + index}
                id={id}
                name={name}
                conceptName={conceptName}
                target={targetId}
                prices={prices}
                images={images}
                sizes={sizes}
                family={family}
                colors={colors}
                url={url}
                sustainabilities={sustainabilities}
                market={market}
                locale={locale}
                colXS={tierSize ? tierSize.xs : colXS}
                colMD={tierSize ? tierSize.md : colMD}
                colLG={tierSize ? tierSize.lg : colLG}
                colXL={tierSize ? tierSize.xl : colXL}
                colXXL={tierSize ? tierSize.xxl : colXXL}
                withHover={withHover}
                withLabel={withLabel}
                item={products[index]}
                camperlab={camperlab}
                newShooting={products[index - 1] && products[index - 1].newShooting} // hay que mirar el anterior porque el efecto del eje x del box-shadow se aplica encima del anterior.
                withSKU={withSKU}
                membersOnly={membersOnly || false}
                recalculating={recalculating}
                voucherApplied={voucherApplied}
                hasModal={withModal}
                squareImg={squareImg}
                saveLastProduct={saveLastProduct}
                noLink={noLink}
              />
            </WithMiddle>
          );
        })
    }
    {
      ![ 'grid', 'search' ].includes(origin) && products && products.length === 0 &&
      <Col span={24}>
        <Empty image={null} imageStyle={{ display: 'none' }} description={<span>{t('searcher', 'no.results')}</span>} />
      </Col>
    }
    </>
  );
}

ProductsGrid.propTypes = {
  origin: string,
  products: array,
  loading: bool,
  colXS: object,
  colMD: object,
  colLG: object,
  colXL: object,
  colXXL: object,
  locale: string,
  withHover: bool,
  withLabel: bool,
  infiniteScrollLoading: bool,
  defaultLimit: number,
  currentList: string,
  camperlab: bool,
  withSKU: bool,
  currentListName: string,
  recalculating: bool,
  voucherApplied: bool,
  withModal: bool,
  page: number,
  loadPage: any,
  squareImg: bool,
};

ProductsGrid.defaultProps = {
  origin: '',
  products: [],
  loading: false,
  colXS: { span: 12 },
  colMD: { span: 8 },
  colLG: { span: 8 },
  colXL: { span: 8 },
  colXXL: { span: 6 },
  withHover: true,
  withLabel: false,
  infiniteScrollLoading: false,
  defaultLimit: DEFAULT_LIMIT,
  camperlab: false,
  withSKU: false,
  recalculating: false,
  voucherApplied: false,
  withModal: false,
  page: 0,
  loadPage: null,
  squareImg: false,
};

export default ProductsGrid;
